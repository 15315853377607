import { graphql, useStaticQuery } from "gatsby";
import { ReviewsContactFormQuery } from "./query.generated";

export type CMSContactFormData = ReviewsContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<ReviewsContactFormQuery>(
    graphql`
      query ReviewsContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            reviewsPage: pages(contentType: "italymondo_home.IMReviewPage") {
              ... on CMS_IMReviewPage {
                contactFormSectionName
                footerContactForm {
                  confirmationPage {
                    url
                  }
                  description
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
