/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useEffect, useRef, useState } from "react";
import { formatFullName, RichText } from "@italymondo/core-ui";
import { Grid, Stack, Typography } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import {
  Avatar,
  Card,
  FeaturedBadge,
  HiddenContainer,
  Info,
  InfoBottom,
  Message,
  MessageContainer,
  MessageOverlay,
  ReadMore,
  ServiceLabel,
  ServicesContainer,
  Source,
} from "./styles";

type PpTestimonialCard = {
  date: string;
  featured: boolean;
  message: string;
  name: string;
  photo: {
    alt: string;
    src: ImageDataLike;
  };
  services: Array<{
    name: string;
  }>;
  source: {
    alt: string;
    src: string;
    url: string;
  };
};

export function TestimonialCard({
  date,
  featured,
  message,
  name,
  photo,
  services,
  source,
}: PpTestimonialCard): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const featuredLabel = "FEATURED";
  /*
    - height: default container height (fixed)
    - contentsHeight: actual content height
    - currentHeight: either height or contentsHeight
  */
  const height = 200;
  const [contentsHeight, setContentsheight] = useState<number>(0);
  const [currentHeight, setCurrentHeight] = useState<number>(height);

  useEffect(() => {
    if (containerRef.current) {
      const dimensions = containerRef.current.getBoundingClientRect();
      const elementHeight = dimensions ? dimensions.height : 0;
      setContentsheight(elementHeight);
    }
  }, []);

  function handleToggleHeight(): void {
    if (currentHeight === height) {
      setCurrentHeight(contentsHeight);
      setIsOpen(true);
    } else {
      setCurrentHeight(height);
      setIsOpen(false);
    }
  }

  const shouldShowShadow =
    contentsHeight !== height && height < contentsHeight && !isOpen;
  const shouldShowToggle = isOpen || shouldShowShadow;

  return (
    <Card>
      {featured ? (
        <FeaturedBadge color="primary.contrastText" variant="badge">
          {featuredLabel}
        </FeaturedBadge>
      ) : null}
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <Grid item sm={4}>
          <Info
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={{ xs: 2, sm: 1 }}
          >
            <Stack
              alignItems="center"
              direction={{ xs: "row", sm: "column" }}
              justifyContent="center"
              spacing={{ xs: 2, sm: 1 }}
            >
              <Avatar alt={photo.alt} image={getImage(photo.src)} />
              <Stack direction="column">
                <Typography
                  align="center"
                  color="primary"
                  variant="caption--xl"
                >
                  {formatFullName(name)}
                </Typography>
                <Typography
                  gutterBottom
                  align="center"
                  color="primary.light"
                  variant="caption"
                >
                  {date}
                </Typography>
              </Stack>
            </Stack>
            <InfoBottom
              alignItems="center"
              direction={{ xs: "row", sm: "column-reverse" }}
              justifyContent="center"
            >
              <Source>
                <img alt={source.alt} src={source.src} />
              </Source>
              {services.length ? (
                <ServicesContainer
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                >
                  {services.map(({ name: serviceName }) => (
                    <ServiceLabel
                      color="primary.contrastText"
                      variant="footer--label"
                    >
                      {serviceName}
                    </ServiceLabel>
                  ))}
                </ServicesContainer>
              ) : null}
            </InfoBottom>
          </Info>
        </Grid>
        <Grid item sm={8}>
          <MessageContainer>
            {shouldShowShadow ? <MessageOverlay /> : null}
            <Message
              color="primary"
              style={{ maxHeight: `${currentHeight}px` }}
              text={message}
              variant="testimonial--lg"
            />
            <HiddenContainer ref={containerRef}>
              <RichText
                aria-hidden="true"
                color="primary"
                text={message}
                variant="testimonial--lg"
              />
            </HiddenContainer>
          </MessageContainer>
        </Grid>
      </Grid>
      {shouldShowToggle ? (
        <ReadMore
          color="primary"
          variant="caption"
          onClick={handleToggleHeight}
        >
          {isOpen ? "Read less" : "Read more"}
        </ReadMore>
      ) : null}
    </Card>
  );
}
