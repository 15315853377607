import React, { useEffect, useMemo, useState } from "react";
import { Button, formatDate, SectionContainer } from "@italymondo/core-ui";
import { Typography } from "@mui/material";
import { useStaticReviewsQuery } from "./query";
import { ContentContainer } from "./styles";
import { TestimonialCard } from "./TestimonialCard";

export function Reviews(): React.ReactElement | null {
  const data = useStaticReviewsQuery();
  const allData = useMemo(() => data.testimonials || [], [data]);
  const [list, setList] = useState([...allData.slice(0, 10)]);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(allData.length > 10);

  const loadMoreButtonText = "Load More";
  const noMoreResults = "No more results.";

  function handleLoadMore(): void {
    setLoadMore(true);
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allData.length;
      const nextResults = isMore
        ? allData.slice(currentLength, currentLength + 5)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [allData, hasMore, list, loadMore]);

  useEffect(() => {
    const isMore = list.length < allData.length;
    setHasMore(isMore);
  }, [allData, list]);

  if (!data) {
    return null;
  }

  return (
    <SectionContainer>
      <ContentContainer>
        {list.map((t) => (
          <TestimonialCard
            date={formatDate(t.date || "")}
            featured={!!t.featureOnReviewPage}
            message={t.fullMessage || ""}
            name={t.name}
            photo={{
              alt: t.photo.alt,
              src: t.photo.urlSharp,
            }}
            services={t.services || []}
            source={{
              alt: t?.sourceDetail?.logo?.title || "",
              src: t?.sourceDetail?.logo?.url || "",
              url: t?.sourceDetail?.url || "",
            }}
          />
        ))}
        {hasMore ? (
          <Button color="primary" variant="contained" onClick={handleLoadMore}>
            {loadMoreButtonText}
          </Button>
        ) : (
          <Typography color="primary" variant="body1">
            {noMoreResults}
          </Typography>
        )}
      </ContentContainer>
    </SectionContainer>
  );
}
