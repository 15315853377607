import React from "react";
import { Hero } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import { ContactForm, Reviews } from "../../sections/Reviews";
import { ReviewsTemplateQuery } from "./index.generated";

type PpReviewsPage = {
  data: ReviewsTemplateQuery;
  location?: {
    pathname?: string;
  };
};

export default function ReviewsPage({
  data: {
    cms: { site },
  },
  location,
}: PpReviewsPage): React.ReactElement | null {
  return (
    <Layout>
      <SEO
        description={site?.reviewsPage[0]?.listingSummary || ""}
        image={site?.reviewsPage[0]?.listingImage?.url}
        location={location}
        title={site?.reviewsPage[0]?.listingTitle || ""}
      />
      <Hero
        description={site?.reviewsPage[0].description || ""}
        headline={site?.reviewsPage[0].headline || ""}
      />
      <Reviews />
      <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query ReviewsTemplate {
    cms {
      site(hostname: "www.italymondo.com") {
        reviewsPage: pages(contentType: "italymondo_home.IMReviewPage") {
          ... on CMS_IMReviewPage {
            description
            headline
            listingImage {
              url
            }
            listingSummary
            listingTitle
          }
        }
      }
    }
  }
`;
