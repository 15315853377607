import { graphql, useStaticQuery } from "gatsby";
import { ReviewsPageTestimonialsQuery } from "./query.generated";

export type CMSReviewsPageTestimonialsData =
  ReviewsPageTestimonialsQuery["cms"];

export function useStaticReviewsQuery(): CMSReviewsPageTestimonialsData {
  const data = useStaticQuery<ReviewsPageTestimonialsQuery>(
    graphql`
      query ReviewsPageTestimonials {
        cms {
          testimonials(includeOnReviewPage: true) {
            date
            featureOnReviewPage
            fullMessage
            name
            photo {
              alt
              url
              urlSharp {
                childImageSharp {
                  gatsbyImageData(height: 75, width: 75, placeholder: BLURRED)
                }
              }
            }
            services {
              name
            }
            sourceDetail {
              logo {
                title
                url
              }
              url
            }
          }
        }
      }
    `
  );
  return data.cms;
}
