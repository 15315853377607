import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { RichText } from "@italymondo/core-ui";
import { Grid, Stack, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

export const Card = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: ${theme.shape.siteBorderRadius.lg};
    box-shadow: ${theme.shadows[1]};
    background-color: ${theme.palette.background.paper};
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(5)};
    ${theme.cssMixins.colStretch};
    ${theme.breakpoints.up("sm")} {
      padding: ${theme.spacing(5)};
    }
  `}
`;

export const FeaturedBadge = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 ${theme.shape.siteBorderRadius.xs} 0;
    max-height: 30px;
    padding: ${theme.spacing(0.625, 2.25)};
    background-color: ${theme.palette.secondary.main};
  `}
`;

export const Info = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    padding-top: ${theme.spacing(2)};
    ${theme.breakpoints.up("sm")} {
      padding: ${theme.spacing(1)};
    }
  `}
`;

export const InfoBottom = styled(Stack)`
  flex-wrap: wrap-reverse;
`;

export const Avatar = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    img {
      border-radius: 100%;
    }
    ${theme.breakpoints.up("sm")} {
      margin-top: ${theme.spacing(1)};
    }
  `}
`;

export const ServicesContainer = styled(Stack)`
  flex-wrap: wrap;
`;

export const ServiceLabel = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.noSelect};
    border-radius: ${theme.shape.siteBorderRadius.xs};
    padding: ${theme.spacing(0.5, 1)};
    background-color: ${theme.palette.secondary.main};
    margin: ${theme.spacing(0.5)} !important;
  `}
`;

export const Source = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin: ${theme.spacing(1)};
    display: inline-flex;
    > img {
      height: 25px;
      width: 60px;
    }
    ${theme.breakpoints.up("sm")} {
      margin-top: ${theme.spacing(2)};
      > img {
        width: 75px;
      }
    }
  `}
`;

export const MessageContainer = styled("div")`
  position: relative;
  overflow: hidden;
`;

export const MessageOverlay = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    ${theme.cssMixins.stretchBottom};
    top: 80px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      ${theme.palette.background.paper}
    );
    background: -moz-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      ${theme.palette.background.paper}
    );
    background: -webkit-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      ${theme.palette.background.paper}
    );
    z-index: 10;
    ${theme.breakpoints.up("sm")} {
      top: 100px;
    }
  `}
`;

export const Message = styled(RichText)`
  ${({ theme }): SerializedStyles => css`
    text-align: left;
    overflow: hidden;
    transition: ${theme.transitions.create("max-height", {
      duration: theme.transitions.duration.complex,
    })};
  `}
`;

export const HiddenContainer = styled("div")`
  opacity: 0;
  position: absolute;
  top: 0;
`;

export const ReadMore = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(3)};
    cursor: pointer;
    align-self: center;
  `}
`;
