import React from "react";
import {
  ContactForm as ContactFormComponent,
  getPathname,
} from "@italymondo/core-ui";
import { useStaticContactFormQuery } from "./query";

export function ContactForm(): React.ReactElement | null {
  const data = useStaticContactFormQuery();

  if (!data.site?.reviewsPage[0]) {
    return null;
  }

  return (
    <ContactFormComponent
      confirmationPageUrl={
        getPathname(
          data.site?.reviewsPage[0]?.footerContactForm?.confirmationPage?.url
        ) || "/"
      }
      description={
        data.site?.reviewsPage[0].footerContactForm.description || ""
      }
      sectionName={data.site?.reviewsPage[0].contactFormSectionName || ""}
      title={data.site?.reviewsPage[0].footerContactForm.title || ""}
    />
  );
}
